<template>
  <div
    class="nav-mega-flyout__content"
  >
    <div
      tag="div"
      class="nav-mega-flyout__content-list-wrapper"
    >
      <div
        v-for="item in contentData"
        :key="item"
      >
        <BlockBrandsList
          v-if="isBrandsList"
          class="nav-mega-flyout__content-brands"
          :data="item"
        />
        <AppNavList
          v-else-if="!isBrandsList"
          class="nav-mega-flyout__content-list"
          :class="{ 'nav-mega-flyout__content-list--has-sub-children': hasSubChildren }"
          :data="item"
          :level="3"
        />
      </div>
    </div>
    <div v-if="data.content && data.content.length" class="nav-mega-flyout__block-wrapper">
      <BlockBannerCta
        v-if="data.content[0].component === 'BlockBannerCta'"
        :data="data.content[0]"
      />

      <BlockProductsFlyout
        v-if="data.content[0].component === 'BlockProductsFlyout'"
        :data="data.content[0]"
      />
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    content: {
        type: Object,
        default: () => {},
    },
});

const hasSubChildren = computed(() => props.data.children[0]?.children?.length);
const contentData = computed(() => [props.data]);

const isBrandsList = computed(() => {
    if (props.data?.brands) {
        return true;
    }
    return false;
});
</script>

<style lang="scss">
.nav-mega-flyout__content {
    @include remove-scrollbars();

    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 174px;
    padding-right: var(--grid-gutter);
    padding-bottom: 30px;
    overflow-y: auto;

    .nav-list-item__title {
        @include typo-font('regular');
        @include typo-size('navLevel3');
    }
}

.nav-mega-flyout__content-list {
    width: 100%;
    margin-bottom: 50px;

    &.nav-mega-flyout__content-list--has-sub-children {
        .nav-list__list {
            @include grid-layout();
            @include fluid('row-gap', 20px, 40px);

            grid-template-columns: 1fr 1fr 1fr;

            .nav-list-item.is-wide{
                // .nav-list-item__children {
                //     @include grid-layout(3);
                // }
                // &.col-span-2 {
                //     grid-column: span 2;
                // }

                // &.col-span-3 {
                //     grid-column: span 3;
                //     .nav-list-item__children {
                //         @include grid-layout(3);
                //     }
                // }
            }
        }
    }
}

.nav-mega-flyout__block-wrapper {
    position: relative;

    .block-banner-cta {
        bottom: 0;
        width: 100%;
    }
    > div {
        margin-bottom: 0;
    }

}

.nav-mega-flyout__content-list-wrapper {
    position: relative;

    .nav-mega-flyout__content-brands {
        position: absolute;
        width: 100%;
    }
    .block-brands-list {
        margin-bottom: 0;

        .atom-heading {
            margin-bottom: 29px;
        }
    }

    .block-brands-list__inner {
        @include grid-layout(4, 1fr);
        @include fluid('grid-gap', 10px, 34px);
    }

    .block-brands-list-item__link {
        height: 113px;
    }
}
</style>
